.navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  height: 13vh;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;

    .logoimg {
      height: 100%;
    }
    .logotext {
      color: white;
      padding: 0 5vw;
      font-size: 1.1rem;
    }
  }
}
