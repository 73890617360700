.picture {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  text-align: center;
  flex-shrink: 1;
  margin-bottom: 3vh;
  border-radius: 5px;
  border: 1px solid white;
  font-weight: bold;
  background: rgba($color: #fff, $alpha: 0.3);

  @media (min-width: 600px) {
    margin: 3vh;
  }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .image {
      height: 32px;
      width: auto;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .resultPictureBtn {
    width: 100%;
    height: auto;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    flex-grow: 2;
  }
  
  .resultPicture {
    border-radius: 5px;
    width: 100%;
    height: auto;
  }
}
