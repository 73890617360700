.searchBox {
  margin: 5vh auto;
  
  .input {
    color: white;
    width: 80vw;
    max-width: 500px;
    padding: 10px 30px;
    border: 2px solid rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    outline: none;
    background: rgba(255, 255, 255, 0.3);
  }
}

.errorMessage {
  font-size: 0.8rem;
  font-weight: 700;

  .errorImage {
    height: 15vh;
    width: auto;
  }
}
