.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  color: white;
  font-size: 0.7rem;

  .socialLink {
    height: 100%;
    display: flex;
    align-items: center;

    .socialImage {
      padding: 0 3vw;
      height: 40%;
    }
  }
}
