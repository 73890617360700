.modalBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  opacity: 1;
  animation: showModal 0.5s ease;

  .modal {
    width: 95%;
    max-width: 720px;
    position: relative;
    padding: 10px;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modalImage {
      width: 90%;
      max-width: 650px;
      max-height: 80vh;
      border-radius: 5px;

      @media (min-width: 800px) {
          width: auto;
      }
    }
    .modalLink {
      text-decoration: none;
      cursor: pointer;
      color: black;
    }
    .modalLink:hover {
      color: gray;
    }
  }
  .modalBtn {
    position: absolute;
    color: white;
    z-index: 1;
    top: 10px;
    right: 10px;
    background-color: black;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: none;
  }
}



@keyframes showModal {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
