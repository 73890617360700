.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 100%;
  color: white;
  font-size: 900;

  .btn {
    background: none;
    border: none;
    height: 100%;
    width: 10vw;
    margin: 0 5vw;
    outline: none;
    cursor: pointer;
  }

  .buttonImage {
    height: 40%;
  }
}
